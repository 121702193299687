import { default as icons_45pagew1ozLXxgRCMeta } from "/var/www/rowadtqnee/rowadtqnee-website/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _91slug_93vItbdwpYJCMeta } from "/var/www/rowadtqnee/rowadtqnee-website/pages/articles/[slug].vue?macro=true";
import { default as indexXVhcOi3GswMeta } from "/var/www/rowadtqnee/rowadtqnee-website/pages/articles/index.vue?macro=true";
import { default as careerGlil1hxiXaMeta } from "/var/www/rowadtqnee/rowadtqnee-website/pages/career.vue?macro=true";
import { default as contactusA5rMYr4iaYMeta } from "/var/www/rowadtqnee/rowadtqnee-website/pages/contactus.vue?macro=true";
import { default as indexRCXRSOUciJMeta } from "/var/www/rowadtqnee/rowadtqnee-website/pages/index.vue?macro=true";
import { default as _91slug_938F6l81oclIMeta } from "/var/www/rowadtqnee/rowadtqnee-website/pages/projects/[slug].vue?macro=true";
import { default as indexwk99r3qIzmMeta } from "/var/www/rowadtqnee/rowadtqnee-website/pages/projects/index.vue?macro=true";
import { default as test4Lc9ptpCNRMeta } from "/var/www/rowadtqnee/rowadtqnee-website/pages/test.vue?macro=true";
export default [
  {
    name: icons_45pagew1ozLXxgRCMeta?.name ?? "icons-page",
    path: icons_45pagew1ozLXxgRCMeta?.path ?? "/_icons",
    meta: {...(icons_45pagew1ozLXxgRCMeta || {}), ...{"layout":"svg-sprite"}},
    alias: icons_45pagew1ozLXxgRCMeta?.alias || [],
    redirect: icons_45pagew1ozLXxgRCMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vItbdwpYJCMeta?.name ?? "articles-slug",
    path: _91slug_93vItbdwpYJCMeta?.path ?? "/articles/:slug()",
    meta: _91slug_93vItbdwpYJCMeta || {},
    alias: _91slug_93vItbdwpYJCMeta?.alias || [],
    redirect: _91slug_93vItbdwpYJCMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexXVhcOi3GswMeta?.name ?? "articles",
    path: indexXVhcOi3GswMeta?.path ?? "/articles",
    meta: indexXVhcOi3GswMeta || {},
    alias: indexXVhcOi3GswMeta?.alias || [],
    redirect: indexXVhcOi3GswMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: careerGlil1hxiXaMeta?.name ?? "career",
    path: careerGlil1hxiXaMeta?.path ?? "/career",
    meta: careerGlil1hxiXaMeta || {},
    alias: careerGlil1hxiXaMeta?.alias || [],
    redirect: careerGlil1hxiXaMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/pages/career.vue").then(m => m.default || m)
  },
  {
    name: contactusA5rMYr4iaYMeta?.name ?? "contactus",
    path: contactusA5rMYr4iaYMeta?.path ?? "/contactus",
    meta: contactusA5rMYr4iaYMeta || {},
    alias: contactusA5rMYr4iaYMeta?.alias || [],
    redirect: contactusA5rMYr4iaYMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/pages/contactus.vue").then(m => m.default || m)
  },
  {
    name: indexRCXRSOUciJMeta?.name ?? "index",
    path: indexRCXRSOUciJMeta?.path ?? "/",
    meta: indexRCXRSOUciJMeta || {},
    alias: indexRCXRSOUciJMeta?.alias || [],
    redirect: indexRCXRSOUciJMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_938F6l81oclIMeta?.name ?? "projects-slug",
    path: _91slug_938F6l81oclIMeta?.path ?? "/projects/:slug()",
    meta: _91slug_938F6l81oclIMeta || {},
    alias: _91slug_938F6l81oclIMeta?.alias || [],
    redirect: _91slug_938F6l81oclIMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwk99r3qIzmMeta?.name ?? "projects",
    path: indexwk99r3qIzmMeta?.path ?? "/projects",
    meta: indexwk99r3qIzmMeta || {},
    alias: indexwk99r3qIzmMeta?.alias || [],
    redirect: indexwk99r3qIzmMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: test4Lc9ptpCNRMeta?.name ?? "test",
    path: test4Lc9ptpCNRMeta?.path ?? "/test",
    meta: test4Lc9ptpCNRMeta || {},
    alias: test4Lc9ptpCNRMeta?.alias || [],
    redirect: test4Lc9ptpCNRMeta?.redirect || undefined,
    component: () => import("/var/www/rowadtqnee/rowadtqnee-website/pages/test.vue").then(m => m.default || m)
  }
]